import React from "react";
import Home from "../views/Home";
import NotFound from "../views/NotFound";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

const Routes = () => {
    return (
        <>
            <Router>
                <ScrollTopBehaviour />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route component={NotFound} />
                </Switch>
            </Router>
        </>
    );
};

export default Routes;
