import React from "react";

const About = () => {
    const calculate_age = () => { 
        const dob = new Date(1996, 11, 20)
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms); 
      
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    return (
        <>
            <div className="row align-items-center justify-content-center">
                <div className="col-lg-6 my-3">
                    <div className="img-box dark-img-box">
                        <img src="img/about/1.jpg" alt="mohammed-faza" />
                    </div>
                </div>

                <div className="col-lg-6 my-3">
                    <div className="typo-box about-me">
                        <h3>Mohammed El Faza</h3>
                        <h5>
                            A <span className="color-theme">Web Developer</span> based
                            in <span className="color-theme">Indonesia</span>
                        </h5>
                        <p>
                            I design and develop services for customers of all sizes,
                            specializing in creating stylish, modern websites, web services
                            and online stores. My passion is to design digital user
                            experiences through the bold interface and meaningful
                            interactions. Check out my Portfolio
                        </p>
                        <div className="row about-list">
                            <div className="col-md-6">
                                <div className="media">
                                    <label>Birthday</label>
                                    <p>20th november 1996</p>
                                </div>
                                <div className="media">
                                    <label>Age</label>
                                    <p>{calculate_age()} Yr</p>
                                </div>
                                <div className="media">
                                    <label>Residence</label>
                                    <p>Indonesia</p>
                                </div>
                                <div className="media">
                                    <label>Address</label>
                                    <p>Jakarta</p>
                                </div>
                            </div>
                            {/* End .col */}

                            <div className="col-md-6">
                                <div className="media">
                                    <label>E-mail</label>
                                    <p>elfazamuhammad1@gmail.com</p>
                                </div>
                                <div className="media">
                                    <label>Phone</label>
                                    <p>+62-812-1919-8307</p>
                                </div>
                                <div className="media">
                                    <label>Freelance</label>
                                    <p>Available</p>
                                </div>
                            </div>
                            {/* End .col */}
                        </div>
                        {/* End .row */}
                        {/* <div className="btn-bar">
                            <a className="px-btn px-btn-theme" href="img/resume.png" download>
                                Download CV
                            </a>
                        </div> */}
                    </div>
                    {/* End .about me */}
                </div>
                {/* End .col */}
            </div>
        </>
    );
};

export default About;
